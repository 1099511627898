import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import React from 'react'
import { Link } from 'gatsby'


import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'

import logo from '../../content/images/logo.png'

class Home extends React.Component {
  render() {
    const { location, data } = this.props

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="Home" />
        <div>
          <section className="home-page d-flex flex-column">
            <div className="logo-side">
				<div>
                	<img src={logo} alt=""/>
				</div>
            </div>
            
            
                <div className="col-md-6 offset-md-6 mt-md-5 pb-5 text-center px-5 pt-5">                  
                  <h1>Welcome!</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                  {/* <Link to="/products" className="btn btn-primary">
                    Our Products
                  </Link> */}
                </div>
                <div className="home-products col-md-6 offset-md-6 d-flex justify-content-center">
                  <Link to="/products" className="image-border product-box mr-5" style={{backgroundImage:'url("./img/AdobeStock_164963108_Preview.jpeg")'}}>
                    <div className="product-desc"><h3>Flowers</h3></div>
                  </Link>
                  {/* <Link to="/products" className="product-box" style={{backgroundImage:'url("./img/AdobeStock_200467217_Preview.jpeg")'}}>
                    <div className="product-desc"><h3>Candles</h3></div>
                  </Link> */}
                </div>
          
            
          </section>
        </div>
      </Layout>
    )
  }
}

export default Home

export const query = graphql`
  query ProfilePageQuery {
    profile: file(name: { eq: "profile" }) {
      childImageSharp {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    work1: file(name: { eq: "work1" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    work2: file(name: { eq: "work2" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    work3: file(name: { eq: "work3" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    back1: file(name: { eq: "back1" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    back2: file(name: { eq: "back2" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
  }
`
